import React from 'react';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Col, Divider, Form, Row, Space, Statistic, Typography } from 'antd';
import { useSelector } from 'react-redux';


const InvetaireGadjet = () => {

    const Inventaire = useSelector((state) => state.Inventaire);
    const productionMensieulle = useSelector((state) => state.productionMensieulle)
    const unitConv = (value) => {
        console.log(Inventaire)
        if (value > 1000) {
            return { value: value / 1000, unite: "ton" }
        } else {
            return { value: value, unite: "kg" }
        }
    }
    return (
        <div >
            <Divider orientation="left">Stock Actuel</Divider>
            <Space size={12}>
                <Card bordered={false}>
                    <Statistic
                        title="PDL0"
                        value={Inventaire?.PDL0 ? unitConv(Inventaire?.PDL0).value : 0}
                        precision={2}
                        valueStyle={{ color: '#3f8600' }}
                        suffix={unitConv(Inventaire?.PDL0).unite}
                    />
                </Card>
                <Card bordered={false}>
                    <Statistic
                        title="PDL26"
                        value={Inventaire?.PDL26 ? unitConv(Inventaire?.PDL26).value : 0}
                        precision={2}
                        valueStyle={{ color: '#cf1322' }}
                        suffix={unitConv(Inventaire?.PDL26).unite}
                    />
                </Card>
                <Card bordered={false}>
                    <Statistic
                        title="Quantité de production convention de LPC par mois"
                        value={productionMensieulle || 0}
                        precision={2}
                        valueStyle={{ color: '#cf1322' }}
                        suffix={"litres"}
                    />
                </Card>
            </Space>
        </div>
    );
}

const PJDPGadjet = ({ wide }) => {

    const pjdpValue = useSelector((state) => state.programmeJournalierDeProduction);

    const unitConv = (value) => {
        if (value > 1000) {
            return { value: value / 1000, unite: "ton" }
        } else {
            return { value: value, unite: "kg" }
        }
    }

    if (wide) {
        return (
            <Space size={12}>
                <Divider orientation="left">Programme journalier de production :</Divider>
                <Card bordered={false}>
                    <Statistic
                        title="PDL0"
                        value={pjdpValue?.PDL0 ? unitConv(pjdpValue?.PDL0).value : 0}
                        precision={2}
                        valueStyle={{ color: '#3f8600' }}
                        suffix={unitConv(pjdpValue?.PDL0).unite}
                    />
                </Card>
                <Card bordered={false}>
                    <Statistic
                        title="PDL26"
                        value={pjdpValue?.PDL26 ? unitConv(pjdpValue?.PDL26).value : 0}
                        precision={2}
                        valueStyle={{ color: '#cf1322' }}
                        suffix={unitConv(pjdpValue?.PDL26).unite}
                    />
                </Card>
            </Space>
        );
    }
    return (
        <div >
            <Divider orientation="left">Programme journalier de production :</Divider>
            <Space size={12}>
                <Card bordered={false}>
                    <Statistic
                        title="PDL0"
                        value={pjdpValue?.PDL0 ? unitConv(pjdpValue?.PDL0).value : 0}
                        precision={2}
                        valueStyle={{ color: '#3f8600' }}
                        suffix={unitConv(pjdpValue?.PDL0).unite}
                    />
                </Card>
                <Card bordered={false}>
                    <Statistic
                        title="PDL26"
                        value={pjdpValue?.PDL26 ? unitConv(pjdpValue?.PDL26).value : 0}
                        precision={2}
                        valueStyle={{ color: '#cf1322' }}
                        suffix={unitConv(pjdpValue?.PDL26).unite}
                    />
                </Card>
            </Space>
        </div>
    );
}


const LaiterieGadjet = () => {

    const Inventaire = useSelector((state) => state.Inventaire);

    return (
        <Card bordered={false}>
            <Row>
                <Typography.Title >Laiterie</Typography.Title>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Statistic
                        title="PDL0"
                        value={Inventaire?.PDL0 ? Inventaire?.PDL0 : 0}
                        precision={2}
                        valueStyle={{ color: '#3f8600' }}
                        suffix="ton"
                    />
                </Col>
                <Col span={12}>
                    <Statistic
                        title="PDL26"
                        value={Inventaire?.PDL26 ? Inventaire?.PDL26 : 0}
                        precision={2}
                        valueStyle={{ color: '#cf1322' }}
                        suffix="ton"
                    />
                </Col>
            </Row>
        </Card>
    );
}

export { InvetaireGadjet, PJDPGadjet };