import React from 'react';
import {
    Button,
    Form,
    Grid,
    Input,
    Select,
    Space,
    Steps,
    theme, Col, Row, Divider, Spin
} from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import appdata from "../../appData.json"
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { setStockActuel, setLogin } from "../../state";


import { useNavigate, useParams } from 'react-router-dom';
import { getLaiteriesList } from '../../db';
import auth from '../../db';


const { Option } = Select;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 14, span: 16 },
};



const steps = [
    {
        title: 'Choisir la Wilaya ',
        content: 'First-content',
    },
    {
        title: 'Choisir la Laiterie',
        content: 'Second-content',
    },
    {
        title: 'Authentification',
        content: 'Last-content',
    },
];

const LoginForm: React.FC = () => {
    const { token } = theme.useToken();



    const usertoken = useSelector((state: any) => state.token)
    const user = useSelector((state: any) => state.user)

    const [selectedWilaya, setSelectedWilaya] = React.useState(0);
    const [selectedLaiterie, setSelectedLaiterie] = React.useState("");
    const [current, setCurrent] = React.useState(0);

    const [message, setMessage] = React.useState("");
    const [loading, setLoading] = React.useState(true);

    const [appData, setAppData] = React.useState({ laiteries: [] });

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const loadingLaiterielist = async () => {


        try {
            const jsonRes = await getLaiteriesList();
            setAppData({ ...appData, laiteries: jsonRes.data });
        } catch (err) {
        }
    }



    React.useEffect(() => {
        if (usertoken) {
            navigate("/")
        } else {
            loadingLaiterielist();
            setLoading(false);
        }
    }, [])
    const [form] = Form.useForm();


    const onFinish = async (values: any) => {
        await login(values);
    };


    const login = async (values: any) => {
        setLoading(true)
        try {
            const loggedIn = await auth.requestLogIn(values)



            if (loggedIn.message === "success") {
                dispatch(
                    setLogin({
                        token: loggedIn?.data.token,

                    })
                );

                navigate("/");
            } else {
                throw loggedIn.message
            }
            setLoading(false);
        } catch (err) {
            setMessage(`Error Connection :${err}`)
            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };


    const onSelectedWilayaChange = (value: number) => {
        var equive = appdata.wilayat.filter((wilaya: any) => (wilaya["codeWilaya"] === value));

        if (equive.length > 0) {
            setSelectedWilaya(value);
            setCurrent(1)
        } else {
            setSelectedWilaya(0);
            setCurrent(0)
        }
        setSelectedLaiterie("")
    };

    const onSelectedSelectedLaiterie = (value: string) => {
        var equive = appdata.laiteries.filter((laiterie: any) => laiterie["codeLaiterie"] === value);

        if (equive.length > 0) {
            setSelectedLaiterie(value);
            setCurrent(2)
        } else {
            setSelectedLaiterie("");
            setCurrent(1)
        }
    };


    const items = steps.map((item, index) => ({ key: index, title: item.title }));

    return (
        <Spin spinning={loading}>

            <Row style={{ padding: 24 }}>
                <Steps current={current} items={items} />
            </Row>
            {
                loading ? <h5>loading ...</h5>
                    :
                    <Form
                        name="basic"
                        {...layout}
                        style={{ maxWidth: 600, margin: 20 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Wilaya"
                            name="codeWilaya"
                            rules={[{ required: true, message: "Veuillez saisir la wilaya!" }]}
                        >
                            <Select
                                placeholder="Choisir la wilaya"
                                onChange={onSelectedWilayaChange}
                                allowClear={false}
                            >
                                {appdata?.wilayat &&
                                    appdata.wilayat.map((wilaya: any, index: number) =>
                                        <Option value={wilaya.codeWilaya} key={index}>{wilaya.codeWilaya} <Divider type='vertical' /> {wilaya.nomWilaya}</Option>)}
                            </Select>
                        </Form.Item>
                        {selectedWilaya > 0 &&

                            <Form.Item name="codeLaiterie" label="Laiterie"
                                rules={[{ required: true, message: "Veuillez saisir la laiterie!" }]}
                            >
                                <Select
                                    placeholder="Choisir un laitier"
                                    onChange={onSelectedSelectedLaiterie}
                                    onClick={loadingLaiterielist}
                                    allowClear
                                    style={{ width: "100%" }}
                                >
                                    {Array.isArray(appData?.laiteries) && appData?.laiteries.map((laiterie: any, index: number) => {
                                        if (laiterie && laiterie["codeWilaya"] === selectedWilaya) {
                                            return (<Option value={laiterie["codeLaiterie"]} key={index}>{index + 1} | {laiterie["nomLaiterie"]}</Option>);
                                        }
                                    })}
                                </Select>
                            </Form.Item>
                        }
                        {
                            <>
                                <Form.Item
                                    label="Utilisateur"
                                    name="username"
                                    rules={[{ required: true, message: "Veuillez saisir votre nom d'utilisateur !" }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Mote de passe :"
                                    name="password"
                                    rules={[{ required: true, message: "Veuillez saisir votre mot de passe !" }]}
                                >
                                    <Input
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        type="password"
                                        placeholder="Mot de pass"
                                    />
                                </Form.Item>
                                <Form.Item {...tailLayout}  >
                                    <Button type="primary" htmlType="submit">
                                        Connexion
                                    </Button>
                                </Form.Item>
                            </>

                        }
                    </Form>
            }
        </Spin>
    );
};





export default LoginForm;