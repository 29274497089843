import React from 'react';
import { Avatar, Breadcrumb, Button, Divider, Image, Layout, Menu, theme } from 'antd';
import LogOut from '../logout';

import logo from "../../assets/images/logo.jpeg"
import Item from 'antd/es/list/Item';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const { Header, Content, Footer } = Layout;

const menuItems = [
    {
        title: "DashBoard",
        path: "/",
        icon: null
    },
    {
        title: "Stock Actuel",
        path: "/inventairereports",
        icon: null
    },
    {
        title: "Consomation ",
        path: "/consomationreports",
        icon: null
    },
    {
        title: "Statistics",
        path: "/statistics",
        icon: null
    }
]

const MainLayout = ({ children }) => {
    const location = useLocation();
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const navigate = useNavigate();
    return (
        <Layout className="layout">
            <Header className='layout-header navbar'>
                <div style={{ display: "inline-flex", width: "100%" }}>
                    <div style={{ display: "inline-flex", justifyContent: "center", }} >
                        <Avatar src={logo} style={{ height: 40, width: 40, margin: 10, borderRadius: 16, overflow: "hidden" }} />
                        ONIL
                    </div>
                    <div className='middle-Line'>
                        {menuItems.map((item, index) =>
                            <div key={index} >
                                {index > 0 && <Divider type="vertical" />}
                                <Link className={item.path === location.pathname ? 'NavLink navlink active' : 'NavLink navlink'}
                                    to={item.path}>
                                    {item?.icon && <item.icon />}
                                    {item?.title && item.title}
                                </Link>
                            </div>)

                        }
                    </div>
                    <div style={{ display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
                        <LogOut />
                    </div>
                </div>
            </Header>
            <Content style={{ padding: '0 50px', minHeight: 430 }}>
                <div className="site-layout-content" style={{ background: colorBgContainer }}>
                    {children}
                </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
                <h5>Office National Interprofessionnel du Lait et des produits laitiers</h5>
                <h5>الديوان الوطني المهني للحليب</h5>
                ONIL ©2023 Created by Ea ger</Footer>
        </Layout >
    );
};

export default MainLayout;
