


const DB_SERVER = process.env.REACT_APP_DB_SERVER || "http://localhost:5000/"

export const getLaiteriesList = async (values) => {
    try {
        const response = await fetch(`${DB_SERVER}appdata/laiteries`, {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        });
        const jsonRes = await response.json();
        return jsonRes
    } catch (err) {
        return { message: "Error loading laiterie list!" }
    }
};

const requestLogIn = async (values) => {
    try {
        const loggedInResponse = await fetch(`${DB_SERVER}auth/login`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(values),
        })
            .catch(err => {
                return { message: `Error Connection :${err.message}` }
            })
        const loggedIn = await loggedInResponse.json();

        return loggedIn
    } catch (err) {
        return { message: `Error Connection :${err}` }
    }
};




export const addReportInventaire = async (usertoken, values) => {
    const response = await fetch(DB_SERVER + "ONIL/addReportInventaire", {
        method: "POST",
        headers: { Authorization: `Bearer ${usertoken}`, "Content-Type": "application/json" },
        body: JSON.stringify({
            ...values,
        }),
    });
    const jsonResponse = await response.json();
    return jsonResponse;
};
export const addReportConsomation = async (usertoken, values) => {
    const response = await fetch(DB_SERVER + "ONIL/addReportConsomation", {
        method: "POST",
        headers: { Authorization: `Bearer ${usertoken}`, "Content-Type": "application/json" },
        body: JSON.stringify({
            ...values,
        }),
    });
    const jsonResponse = await response.json();
    if (jsonResponse.message === "success") {
        console.log('====================================');
        console.log("success ! adding ONIL Inventaire_Report :", jsonResponse.data);
        console.log('====================================');
    } else {
        console.log('====================================');
        console.log("failed ! adding ONIL Inventaire_Report :", jsonResponse.message);
        console.log('====================================');
    }
    return jsonResponse;
};

export const getInventaireReportsIdList = async (usertoken) => {

    try {
        const response = await fetch(DB_SERVER + "ONIL/list/inventaire", {
            method: "GET",
            headers: { Authorization: `Bearer ${usertoken}`, "Content-Type": "application/json" }
        });

        const jsonResponse = await response.json();

        if (jsonResponse.message !== "success")
            throw "failed ! didn't got ONIL Inventaire reports :" + jsonResponse.message;
        return jsonResponse;
    } catch (err) {
        console.log(err)
        return { message: err }
    }
};

export const getConsomationReportsReportsList = async (usertoken) => {

    try {
        const response = await fetch(DB_SERVER + "ONIL/list/consomation", {
            method: "GET",
            headers: { Authorization: `Bearer ${usertoken}`, "Content-Type": "application/json" }
        });

        const jsonResponse = await response.json();

        if (jsonResponse.message !== "success")
            throw "failed ! didn't got ONIL Inventaire reports :" + jsonResponse.message;
        return jsonResponse;
    } catch (err) {
        return { message: err }
    }
};

export const getInventaireReportById = async (usertoken, rpId) => {
    try {
        const response = await fetch(DB_SERVER + "ONIL/list/inventaire/" + rpId, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${usertoken}`, "Content-Type": "application/json"
            }
        })
        const jsonResponse = await response.json();
        if (jsonResponse.message === "success") {
            console.log('====================================');
            console.log("success ! got ONIL Inventaire reports ,Total:", jsonResponse.data.length);
            console.log('====================================');
        } else {
            console.log('====================================');
            console.log("failed ! didn't got ONIL Inventaire reports :", jsonResponse.message);
            console.log('====================================');
        }
        return jsonResponse;
    } catch (err) {
        return { message: err }
    }
};

export const getLaiterieInventaire = async (usertoken) => {
    try {
        const response = await fetch(DB_SERVER + "laiterie/inventaire", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${usertoken}`, "Content-Type": "application/json"
            }
        })
        const jsonResponse = await response.json();
        if (jsonResponse.message !== "success")
            throw jsonResponse;


        console.log('====================================');
        console.log(jsonResponse);
        console.log('====================================');
        return jsonResponse;
    } catch (err) {
        console.log('====================================');
        console.log(err);
        console.log('====================================');
        return err
    }
}

export const getAppData = async (usertoken) => {
    try {
        const response = await fetch(DB_SERVER + "appdata", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${usertoken}`, "Content-Type": "application/json"
            }
        })
        const jsonResponse = await response.json();
        if (jsonResponse.message !== "success")
            throw jsonResponse;

        return jsonResponse;
    } catch (err) {
        return err
    }
}

export const getUpdate = async (usertoken) => {
    try {
        const response = await fetch(DB_SERVER + "laiterie/getUpdate", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${usertoken}`, "Content-Type": "application/json"
            }
        })
        const jsonResponse = await response.json();
        if (jsonResponse.message !== "success")
            throw jsonResponse;

        return jsonResponse;
    } catch (err) {
        return err
    }
}

export default { requestLogIn }  