import React from 'react';
import {
    Layout,
    Menu,
    Button,
    Typography,
    Card,
    Form,
    Input,
    Checkbox,
    Divider,
    Space,
    Image,
    Row,
    Statistic,
    Col,
} from "antd";


import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";

import {
    DribbbleOutlined,
    TwitterOutlined,
    InstagramOutlined,
    GithubOutlined,
} from "@ant-design/icons";

import onil from "../../assets/images/onil.png"
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from '../../components/mainlayout';
import { InvetaireGadjet, PJDPGadjet } from '../../components/uigadjets';
import { ConsomationReportsForm, StockActuelReportForm } from '../reportforms';



const { Header, Footer, Content, Sider } = Layout;

const siderStyle: React.CSSProperties = {
    textAlign: 'center',
    lineHeight: '180px',
    color: '#fff',
    backgroundColor: '#fff0',
    padding: 10,
    height: "100vh",
    width: 280,
    position: "fixed",
};
function DashBoard() {

    const [loading, setloading] = React.useState(true)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const refresh = () => {
    };


    return (
        <MainLayout >
            <Row gutter={24}>
                <Col span={12}>
                    <InvetaireGadjet />
                </Col>
            </Row>
        </MainLayout>
    );
}

export default DashBoard