import { Button, Card, Cascader, Col, Descriptions, Divider, Form, Input, InputNumber, Result, Row, Select, Space, Spin, Tag, Typography, theme } from 'antd'
import React from 'react'

import { CloseOutlined, CloseSquareTwoTone, SaveTwoTone } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { addReportConsomation, addReportInventaire, getAppData } from '../../db'
import { setStockActuel } from '../../state'
import MainLayout from '../../components/mainlayout';

import localAppdata from "../../appData";


import { object, string, number, date, InferType } from 'yup';







const StockActuelReportForm = () => {
    const [loading, setLoading] = React.useState(false);
    const [saved, setSaved] = React.useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const Inventaire = useSelector((state) => state.Inventaire);
    const [PDL0, setPDL0] = React.useState(0);
    const [PDL26, setPDL26] = React.useState(0);
    const [quotaId, setQuotaId] = React.useState(null);
    const [selectedQuota, setSelectedQuota] = React.useState(null);


    const currentQuotas = useSelector(state => state.currentQuotas)
    const user = useSelector((state) => state.user);
    const usertoken = useSelector((state) => state.token);



    const { token } = theme.useToken();
    const [form] = Form.useForm();

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };


    const onFinish = async (values) => {

        if (!usertoken) { return; }
        setLoading(true);
        await addReportInventaire(usertoken, values)
            .then(res => {
                if (res.message === "success") {
                    dispatch(setStockActuel({ setStockActuel: res.Invenotaire }));
                    setSaved(res.message);
                }
                else {
                    setSaved(res.message);
                }
                setLoading(false);
            })
            .catch(err => {
                setSaved(err)
                setLoading(false);
            });
        setLoading(false);
    };

    //currentQuotas.Fournisseur
    if (saved !== "") {
    }

    return (
        <MainLayout >
            {(Array.isArray(currentQuotas) && currentQuotas.length > 0) ?

                <Card
                    title={<h5>Quota du mois (ONIL)</h5>}
                    extra={
                        <Button onClick={() => navigate("/inventairereports")}>Close</Button>}
                    bordered={false}
                >
                    {saved === "" ?
                        <Spin spinning={loading}>
                            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                                <Divider orientation='right'  >La date du rapport : {new Date().toLocaleDateString()}</Divider>
                                <Row>
                                    <Space size={10}>
                                        <Form.Item
                                            style={{ minWidth: 300 }}
                                            label="Quota"
                                            name="quotaId"
                                        >
                                            <Select
                                                placeholder={"Choisir la quota"}
                                                style={{ minWidth: "100%" }}
                                                onChange={(v) => {
                                                    setQuotaId(v);
                                                    setSelectedQuota(currentQuotas.find(q => q._id === v));
                                                }}>
                                                {currentQuotas.map((quota, index) =>
                                                    <Select.Option key={index} value={quota._id} >Quota N:{index + 1}</Select.Option>
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Space>

                                </Row>
                                {quotaId &&
                                    <>
                                        <Row>
                                            <Divider orientation='left' >Quota</Divider>
                                            <Descriptions title="Quota details"
                                            >
                                                <Descriptions.Item label="Fournisseur">{selectedQuota?.fournisseur}</Descriptions.Item>
                                                <Descriptions.Item label="LOTs">{selectedQuota?.lot?.map((item, index) => <Tag key={index}>{item}</Tag>)}</Descriptions.Item>
                                                <Descriptions.Item label="La date du quota">{new Date(selectedQuota?.date).toLocaleString()}</Descriptions.Item>
                                            </Descriptions>
                                            <Descriptions title="Quantités du quota">
                                                <Descriptions.Item label="Quantité Fixer par ONIL">
                                                    0% : {selectedQuota.quantity.PDL0} kg
                                                    <Divider type='vertical' />
                                                    26% : {selectedQuota.quantity.PDL26}  kg
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Quantité recevez">
                                                    0% : {selectedQuota.submited.PDL0} kg
                                                    <Divider type='vertical' />
                                                    26% : {selectedQuota.submited.PDL26} kg
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Quantité restant a livrer">
                                                    0% : {selectedQuota.quantity.PDL0 - selectedQuota.submited.PDL0} kg
                                                    <Divider type='vertical' />
                                                    26% : {selectedQuota.quantity.PDL26 - selectedQuota.submited.PDL26} kg
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Row>
                                        <Divider orientation='left' >La quantité reçue</Divider>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <h4>Entree</h4>
                                                <Form.Item label="PDL 0%" name={"PDL0"}
                                                    rules={[{ required: true, message: "Veuillez saisir la quantité!" }]}>
                                                    <Input
                                                        onChange={(e) => setPDL0(e.target.value)}
                                                        type='number'
                                                        placeholder='Quantité'
                                                        suffix={<Tag>kg</Tag>}
                                                    />
                                                </Form.Item>
                                                <Form.Item label="PDL 26%" name={"PDL26"}
                                                    rules={[{ required: true, message: "Veuillez saisir la quantité!" }]} >
                                                    <Input
                                                        onChange={(e) => setPDL26(e.target.value)}
                                                        type='number'
                                                        placeholder='Quantité'
                                                        suffix={<Tag>kg</Tag>} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2} >
                                                <Divider type='vertical' style={{ height: "100%" }} />
                                            </Col>
                                            <Col span={10} style={{ textAlign: 'left' }}>
                                                <Divider orientation='left' >Stock</Divider>
                                                <Row>
                                                    <Descriptions title="Initial">
                                                        <Descriptions.Item label="0%" > <Tag>{toTonne(parseFloat(Inventaire?.PDL0 ? Inventaire?.PDL0 : 0))} </Tag></Descriptions.Item>
                                                        <Descriptions.Item label="26%"> <Tag>{toTonne(parseFloat(Inventaire?.PDL26 ? Inventaire?.PDL26 : 0))} </Tag></Descriptions.Item>
                                                    </Descriptions>
                                                </Row>
                                                <Divider />
                                                <Row>
                                                    <Descriptions title="Final">
                                                        <Descriptions.Item label="0%"> <Tag>{toTonne(parseFloat(Inventaire?.PDL0 ? Inventaire?.PDL0 : 0) + parseFloat(PDL0))}</Tag></Descriptions.Item>
                                                        <Descriptions.Item label="26%"> <Tag>{toTonne(parseFloat(Inventaire?.PDL26 ? Inventaire?.PDL26 : 0) + parseFloat(PDL26))}</Tag></Descriptions.Item>
                                                    </Descriptions>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Divider type='horizontal' style={{ width: "100%" }} />
                                        <Row gutter={24} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Space size={12}>
                                                <Button type="primary" htmlType="submit">
                                                    <SaveTwoTone />
                                                    Enregistrer
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        form.resetFields();
                                                    }}
                                                >
                                                    <CloseSquareTwoTone />
                                                    Clear
                                                </Button>
                                            </Space>

                                        </Row>
                                    </>
                                }
                            </Form>
                        </Spin>
                        :
                        <Result
                            status={saved === "success" ? "success" : "error"}
                            title={saved === "success" ? "Success" : "Erreur"}
                            subTitle={saved === "success" ? "Rapport vas sauvgarde avec success" : saved}
                            extra={
                                <Button type="primary" onClick={() => setSaved("")}>
                                    {saved === "success" ? "Ajouter un autre" : "Ressayer"}
                                </Button>
                            }
                        />
                    }
                </Card > :
                <Result
                    status="info"
                    title="No avaliable quotas (×-×)"
                    extra={
                        <Button type="primary" onClick={() => navigate("/")}>
                            Aller à la dashboard
                        </Button>
                    }
                />

            }
        </MainLayout>

    );
}


const toTonne = (v) => {
    if (!v)
        return "0-kg"

    if (v > 1000)
        return v / 1000 + "tonne"
    else
        return v + "kg"
}

const ConsomationReportsForm = () => {

    let consoReportSchema = object({
        PDL0: number().required().positive(),
        PDL26: number().required().positive(),
        Production_LPC: number().required().positive(),
        Sortie: number().required().positive(),
    });

    const [loading, setLoading] = React.useState(false);
    const [saved, setSaved] = React.useState("");


    const [appdata, setAppdata] = React.useState({});

    const [theseCommunes, setTheseCommunes] = React.useState([]);

    const [communesData, setCommunesData] = React.useState([]);



    const Inventaire = useSelector((state) => state.Inventaire);
    const [PDL0, setPDL0] = React.useState(0);
    const [PDL26, setPDL26] = React.useState(0);

    const { token } = theme.useToken();
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [zoneDestribtution, setZoneDestri] = React.useState([])
    const usertoken = useSelector((state) => state.token);

    const loadAppData = async () => {
        await getAppData(usertoken).then(docs => {
            if (docs?.message === "success") {
                setAppdata(docs.data)
                let options = []
                localAppdata.wilayat.forEach((wilaya, index) => {
                    options.push({
                        key: index,
                        value: wilaya.codeWilaya,
                        label: wilaya.codeWilaya + "-" + wilaya.nomWilaya,
                        children:
                            docs.data.communes.filter((commune, index) => commune.codeWilaya === wilaya.codeWilaya).map(commune => {
                                return ({
                                    key: index,
                                    value: commune.codeCommune,
                                    label: commune.codeCommune + "-" + commune.nomCommune,
                                });
                            })

                    });
                    setCommunesData(options)
                })
            }
        });
    }

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    const onFinish = async (values) => {

        if (!token)
            return
        setLoading(true);

        const formdata = {
            PDL0: values.PDL0, PDL26: values.PDL26,
            Production_LPC: values.Production_LPC,
            distination: zoneDestribtution,
            sortie: values.Sortie

        }

        await addReportConsomation(usertoken, formdata)
            .then(res => {
                if (res.message === "success") {
                    setSaved(res.message);
                }
                else {
                    setSaved(res.message);
                }
                setLoading(false);
            })
            .catch(err => {
                setSaved(err)
                setLoading(false);
            });
    };


    const addDist = () => {
        const distinationInfo = form.getFieldValue("distinationInfo")
        if (!Array.isArray(distinationInfo) || distinationInfo?.length < 2)
            return
        const cW = distinationInfo[0];
        const cC = distinationInfo[1];
        const cQ = form.getFieldValue("quantity");

        const Production_LPC = form.getFieldValue("Production_LPC");
        const sortie = form.getFieldValue("Sortie");

        let notAdded = zoneDestribtution.filter(zD => zD.codeWilaya === cW && zD.codeCommune === cC).length < 1;
        let sumQ = 0;
        zoneDestribtution.forEach(zD => sumQ = sumQ + zD.quantity);
        if (cW && cC && notAdded && sumQ <= Production_LPC && sumQ+cQ <= sortie)
            setZoneDestri([...zoneDestribtution, { codeWilaya: cW, codeCommune: cC, quantity: cQ }]);
    }

    React.useEffect(() => { loadAppData(); }, []);


    return (
        <MainLayout >
            <Card
                title={<h5>Consommation de jour (ONIL)</h5>}
                extra={
                    <Button onClick={() => navigate("/consomationreports")}>Close</Button>}
                bordered={false}
            >
                {saved === "" &&
                    <Spin spinning={loading}>
                        <Form form={form} name="consomation report" style={formStyle} onFinish={onFinish}>
                            <Row
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                }}>
                                <Col md={24} lg={12}>
                                    <Divider orientation='left'>Consomation PDL (Kg)</Divider>
                                    <Row>
                                        <Col xs={24} lg={11}>
                                            <Form.Item label="PDL 0%" name={"PDL0"}
                                                rules={[{ required: true, message: "Veuillez saisir la quantité!" }]}>
                                                <InputNumber
                                                    style={{ flex: 1, minWidth: "100%" }}
                                                    onChange={(v) => setPDL0(v)}

                                                    placeholder='Quantité'
                                                    suffix={<Tag>kg</Tag>}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={2}><Divider type='vertical' /></Col>
                                        <Col xs={24} lg={11}>
                                            <Form.Item label="PDL 26%" name={"PDL26"}
                                                rules={[{ required: true, message: "Veuillez saisir la quantité!" }]} >
                                                <InputNumber
                                                    style={{ flex: 1, minWidth: "100%" }}
                                                    onChange={(v) => setPDL26(v)}

                                                    placeholder='Quantité'
                                                    suffix={<Tag>kg</Tag>} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} lg={11}>
                                            <Form.Item label="Production LPC" name="Production_LPC"
                                                rules={[{ required: true, message: "Veuillez saisir la quantité!" }]} >
                                                <InputNumber
                                                    style={{ flex: 1, minWidth: "100%" }}
                                                    placeholder='Quantité'
                                                    suffix={<Tag>Litres</Tag>}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={2}><Divider type='vertical' /></Col>
                                        <Col xs={24} lg={11}>
                                            <Form.Item label="Sortie" name="Sortie"
                                                rules={[{ required: true, message: "Veuillez saisir la quantité!" }]} >
                                                <InputNumber
                                                    style={{ flex: 1, minWidth: "100%" }}
                                                    placeholder='Quantité'
                                                    suffix={<Tag>Litres</Tag>}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Divider orientation='left'>Distination</Divider>

                                    {zoneDestribtution.map((i, index) => {
                                        return (
                                            <Typography key={index} >
                                                <pre
                                                    style={{ display: "inline-flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    Wilaya :
                                                    {i.codeWilaya} -
                                                    {localAppdata.wilayat.find(w => w.codeWilaya === i.codeWilaya)?.nomWilaya}
                                                    <Divider type='vertical' />

                                                    Commune :
                                                    {i.codeCommune} -
                                                    {appdata.communes.find(c => c.codeWilaya === i.codeWilaya)?.nomCommune}
                                                    <Divider type='vertical' />
                                                    Quantité :
                                                    {i.quantity} litres
                                                    <Button
                                                        shape="round"
                                                        icon={<CloseOutlined style={{ textAlign: "center", alignContent: "center" }} />}
                                                        onClick={() => {
                                                            setZoneDestri(zoneDestribtution.filter(z => z.codeCommune !== i.codeCommune))
                                                        }} />
                                                </pre>
                                            </Typography>
                                        );
                                    })}
                                    <Form.Item
                                        label="Wilaya / Commune"
                                        name="distinationInfo"
                                    >
                                        <Cascader
                                            options={communesData}
                                            onClick={loadAppData}
                                            placeholder="Voulliez choisir la commune" />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ flex: 1 }}
                                        label="Quantité"
                                        name="quantity"
                                        rules={[{ required: true, message: "Veuillez saisir la quantity!" }]}
                                    >
                                        <Input
                                            type='number'
                                        />
                                    </Form.Item>
                                    <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button onClick={addDist} >Ajouter </Button>
                                    </Row>
                                </Col>
                                <Col md={24} lg={12}>
                                    <Row>
                                        <h3>La date du report : {new Date().toLocaleDateString()}</h3>
                                    </Row>
                                    <Divider />
                                    <Row>
                                        <Descriptions title="Stock initial">
                                            <Descriptions.Item label="0%" > <Tag>{toTonne(parseFloat(Inventaire?.PDL0 ? Inventaire?.PDL0 : 0))} </Tag></Descriptions.Item>
                                            <Descriptions.Item label="26%"> <Tag>{toTonne(parseFloat(Inventaire?.PDL26 ? Inventaire?.PDL26 : 0))} </Tag></Descriptions.Item>
                                        </Descriptions>
                                    </Row>
                                    <Divider />
                                    <Row>
                                        <Descriptions title="Stock final">
                                            <Descriptions.Item label="0%"> <Tag>{toTonne(parseFloat(Inventaire?.PDL0 ? Inventaire?.PDL0 : 0) - PDL0)}</Tag></Descriptions.Item>
                                            <Descriptions.Item label="26%"> <Tag>{toTonne(parseFloat(Inventaire?.PDL26 ? Inventaire?.PDL26 : 0) - PDL26)}</Tag></Descriptions.Item>
                                        </Descriptions>
                                    </Row>
                                </Col>
                            </Row>
                            <Divider type='horizontal' style={{ width: "100%" }} />
                            <Row gutter={24} style={{ textAlign: 'right' }}>
                                <Col span={24} style={{ textAlign: 'right' }}>
                                    <Button type="primary" htmlType="submit">
                                        <SaveTwoTone />
                                        Enregistrer
                                    </Button>
                                    <Button
                                        style={{ margin: '0 8px' }}
                                        onClick={() => {
                                            form.resetFields();
                                            setZoneDestri([])
                                        }}
                                    >
                                        <CloseSquareTwoTone />
                                        Clear
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                }

                {saved !== "" &&
                    <Result
                        status={saved === "success" ? "success" : "error"}
                        title={saved === "success" ? "Success" : "Error"}
                        subTitle={saved === "success" ? "Report was saved successfully" : "Submission Failed"}
                        extra={
                            <Button type="primary" onClick={() => setSaved("")}>
                                {saved === "success" ? "Fill another report" : "Try again"}
                            </Button>
                        }
                    />
                }
            </Card >
        </MainLayout>

    );
}

export {
    StockActuelReportForm, ConsomationReportsForm
}