import React, { useState } from 'react';
import Login from './pages/login';


import {
  Route,
  Routes,
  useNavigate
} from "react-router-dom";
import DashBoard from './pages/dashboard';


import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import "./assets/styles/costum.css"
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'antd';
import { setQuotasData, setPJDP, setStockActuel, clear, setProductionMensieulle } from './state';
import { NotFound, CommingSoon } from './pages/utils';


import { ConsomationReportsForm, StockActuelReportForm } from './pages/reportforms';
import StockActuelReports from './pages/stockactuelreports';
import { getLaiterieInventaire, getUpdate } from './db';
import ConsomationReports from './pages/consomationreports';
import { ProtectedRoute } from './pages/protectedAuth';

function App() {
  const usertoken = useSelector((state) => state.token);

  const quotasData = useSelector((state) => state.quotasData);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true)
  const loadAppData = async () => {
    try {

      const jsonData = await getLaiterieInventaire(usertoken);
      if (jsonData.message === "success") {
        console.log(jsonData.data);
        dispatch(setStockActuel({ PDL0: jsonData.data.PDL0, PDL26: jsonData.data.PDL26 }));
      } else {
        throw jsonData.message
      }
    } catch (err) {
      console.log('====================================');
      console.log("Error getting data : check your internet");
      console.log('====================================');
    }
    try {
      const jsonData = await getUpdate(usertoken);

      if (jsonData.message === "success") {
        console.log(jsonData.data);
        dispatch(setQuotasData({ currentQuotas: jsonData.data.currentQuotas }));
        dispatch(setProductionMensieulle({ value: jsonData.data.productionMensieulle }));
      } else {
        throw jsonData.message
      }
    }
    catch (err) {
      dispatch(setQuotasData(null))
    }
  }


  function requireAuth() {
    if (!usertoken && window.location.pathname !== "/login") {
      dispatch(clear());
      window.location.pathname = "/login"
    }
  }



  React.useEffect(() => {
    requireAuth();
    if (!usertoken)
      return
    loadAppData();
  })

  return (
    <div className="App">
      <Routes >
        <Route path="/" >
          <Route path='/' element={
            <DashBoard />} />
          <Route path="inventairereports" element={
            <StockActuelReports />
          } />
          <Route path="addinventairereport" element={<StockActuelReportForm />} />

          <Route path="consomationreports" element={<ConsomationReports />} />
          <Route path="addconsomationreport" element={<ConsomationReportsForm />} />
          <Route path="statistics" element={<CommingSoon to="/" />} />
          <Route path='*' element={<NotFound to="/" />} />
        </Route>
        <Route path="/info" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
      </Routes>

    </div>
  );
}

export default App;
