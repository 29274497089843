import React from 'react'
import MainLayout from '../../components/mainlayout'
import { Button, Card, Col, Divider, List, Result, Row, Space, Spin, Table } from 'antd'
import { useNavigate } from 'react-router-dom'

import { SyncOutlined, FileAddOutlined, ReloadOutlined } from "@ant-design/icons";
import { useSelector } from 'react-redux';
import { getInventaireReportsIdList } from '../../db';


const dataHeader = [{ title: "Stock initial", index: "Stock_initial" },
{ title: "Entreé", index: "Entree" },
{ title: "Stock final", index: "Stock_final" }]

function StockActuelReports() {

    const { Column, ColumnGroup } = Table;

    const [dataSource, setDataSource] = React.useState([]);
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();


    const usertoken = useSelector((state) => state.token);

    const load = async () => {
        setLoading(true);
        try {
            const answer = await getInventaireReportsIdList(usertoken);

            if (answer.message === "success") {
                let newData = [];
                answer.data.forEach((item, index) => {
                    newData.push({
                        key: item._id,
                        reportId: item.reportId,
                        reportDate: new Date(item.reportDate).toLocaleDateString(),
                        "Stock_initial-PDL0": item.laitData.Stock_initial["PDL0"],
                        "Stock_initial-PDL26": item.laitData.Stock_initial["PDL26"],
                        "Entree-PDL0": item.laitData.Entree["PDL0"],
                        "Entree-PDL26": item.laitData.Entree["PDL26"],
                        "Stock_final-PDL0": item.laitData.Stock_final["PDL0"],
                        "Stock_final-PDL26": item.laitData.Stock_final["PDL26"],
                        fournisseur: item.fournisseur,
                        lot: item.lot,
                    })
                })
                setDataSource(newData);
                setLoading(false);
            } else {
                throw "Faild getting reports list :" + answer.message;
            }
        } catch (err) {

            console.log('====================================');
            console.log("Error ! check your internet");
            console.log('====================================');
            setLoading(false);
        }
    }

    React.useEffect(() => { load(); }, []);

    return (
        <MainLayout >
            <Card title={"Stock Actuel"}
                extra={
                    <Space key={"refresh1"}>
                        <Button type="primary" key={"refresh1"} onClick={load} >
                            <SyncOutlined />
                            Refresh
                        </Button>
                        <Button
                            key={"addreports1"}
                            onClick={() => navigate("/addinventairereport")}
                            type="primary"  >
                            <FileAddOutlined />
                            Ajouter une raport
                        </Button>
                    </Space>

                }>
                <Spin spinning={loading} >
                    {dataSource.length < 1 ?
                        <Result
                            title="No reports here"
                            extra={<Space size={10}>
                                <Button
                                    key={"extra1"}
                                    type="primary" onClick={load}>
                                    <SyncOutlined />
                                    Refresh
                                </Button>
                                <Button
                                    key={"extra2"}
                                    onClick={() => navigate("/addinventairereport")}
                                    type="primary" >
                                    <FileAddOutlined />
                                    Ajouter une raport
                                </Button>
                            </Space >
                            }
                        />
                        :
                        <div>
                            <Table
                                bordered={true}

                                pagination={{
                                    pageSize: 50,
                                }}
                                scroll={{
                                    y: 500,
                                }}
                                dataSource={dataSource}>
                                <Column title="Date" dataIndex="reportDate" key="date" />
                                {dataHeader.map((coll, index) => {
                                    return (
                                        <ColumnGroup title={coll.title} key={index}>
                                            <Column title="0%" dataIndex={coll.index + "-PDL0"} key={index + coll.index + "-PDL0"} />
                                            <Column title="26%" dataIndex={coll.index + "-PDL26"} key={index + coll.index + "-PDL26"} />
                                        </ColumnGroup>);
                                })}
                                <Column title="LOT" dataIndex="lot" key="lot" />
                                <Column title="Fournisseur" dataIndex="fournisseur" key="fournisseur" />
                            </Table>

                        </div>
                    }
                </Spin>
            </Card>
        </MainLayout>
    )
}

export default StockActuelReports