import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  codeLaiterie: null,
  nomLaiterie: null,
  productionMensieulle: 0,
  refreshTrigger: 0,
  currentQuotas: [],
  Inventaire: { PDL0: 0, PDL26: 0 },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.codeLaiterie = action.payload.codeLaiterie;
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;
      state.codeLaiterie = null;
    },
    setLaiterieInfo: (state, action) => {
      state.codeLaiterie = action.payload.codeLaiterie;
      state.nomLaiterie = action.payload.nomLaiterie;
      state.codeWilaya = action.payload.codeWilaya;
    },
    setStockActuel: (state, action) => {
      state.Inventaire = { ...action.payload }
    },
    setProductionMensieulle: (state, action) => {
      state.productionMensieulle = action.payload.value
    },
    setQuotasData: (state, action) => {
      state.currentQuotas = action.payload?.currentQuotas;
    },
    clear: (state, action) => {
      state = {
        user: null,
        token: null,
        codeLaiterie: null,
        nomLaiterie: null,
        setProductionMensieulle: 0,
        refreshTrigger: 0,
        currentQuotas: [],
        Inventaire: { PDL0: 0, PDL26: 0 },
      }
    }
  },
});

export const { setMode,
  setLogin,
  setLogout,
  setStockActuel,
  setProductionMensieulle,
  setQuotasData, clear } = authSlice.actions;

export default authSlice.reducer;
